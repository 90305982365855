<template>
  <v-navigation-drawer temporary clipped v-model="drawer" app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Sonakshi Pandey
          </v-list-item-title>
          <v-list-item-subtitle>
            Full Stack Developer
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.to"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    data: () =>({
        items: [
          { title: 'Home', icon: 'mdi-view-dashboard', to:'/' },
          { title: 'Workspace', icon: 'mdi-image', to:'/workspace' },
          { title: 'Hire Me', icon: 'mdi-office-building', to:'hire-me' },
          { title: 'About Me', icon: 'mdi-account', to:'about' },
        ],
        right: null,
    }),
    computed: {
        drawer:{
            get() {
                return this.$store.getters['getDrawer']
            },
            set(val) {
                this.$store.commit('setDrawer', val)
            }
        }
    }
}
</script>

<style>

</style>