<template>
  <v-footer height="auto" dark>
    <v-container fluid>
      <v-row class="justify-center text-center ml-13">
        <v-col md="11" sm="11" cols="12">
          <v-btn v-for="(task, index) in icons" :key="index" class="mx-4" icon :href="task.link">
            <v-icon size="24px" color="white" >
              {{ task.icon }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-divider></v-divider>
      </v-row>
      <v-row class="justify-center text-center">
        <v-col md="11" sm="11" cols="12">
          {{ new Date().getFullYear() }} — <strong>Sonakshi</strong>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    //icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    icons: [
    { icon: 'mdi-facebook', link :'https://www.facebook.com/nini.shukla.7' },
    { icon: 'mdi-twitter', link :'https://twitter.com/Sonakshishukla1' },
    { icon: 'mdi-linkedin', link :'https://www.linkedin.com/in/sonakshi15/' },
    { icon: 'mdi-instagram', link :'https://www.instagram.com/iamsonakshi_/' }
    ]
  }),
};
</script>

<style>
</style>