<template>
  <v-app-bar
    fixed
    app
    dark
    style="
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12), inset 0 -1px 0 0 black;
    "
  >
    <v-app-bar-nav-icon
      class="d-md-none d-xl-none d-lg-none"
      v-on:click="toggleDrawer"
    ></v-app-bar-nav-icon>

    <v-toolbar-title  class="google-font mr-15" style="width: 200px">Sonakshi.xyz</v-toolbar-title>

    <v-spacer></v-spacer>

    <v-tabs color="white" class="d-none d-sm-none d-md-block d-lg-block d-xl-block ml-15">
      <v-tab to="/" class="ma-4 #FFFFFF">Home</v-tab>
      <v-tab to="/workspace" class="ma-4">WorkSpace</v-tab>
      <v-tab to="/hire-me" class="ma-4">Hire Me</v-tab>
      <v-tab to="/about" class="ma-4">About Me</v-tab>
    </v-tabs>
  </v-app-bar>
</template>

<script>
export default {
  name: "appToolbar",
  methods: {
    toggleDrawer() {
      this.$store.commit('toggleDrawer')
    }
  }
};
</script>

<style>
</style>