<template>
    <div>
        <v-btn
              icon
              color="white"
              href="https://www.linkedin.com/in/sonakshi15/"

            >
              <v-icon>mdi-linkedin</v-icon>
            </v-btn>
      
            <v-btn
              icon
              color="white"
              href="https://twitter.com/Sonakshishukla1"

            >
              <v-icon>mdi-twitter</v-icon>
            </v-btn>
            <v-btn
              icon
              color="white"
              href="https://github.com/sonakshi1501"
            >
              <v-icon>mdi-github</v-icon>
            </v-btn>
    </div>
  </template>
  
  <script>
  
  export default {
      name : "AppSocialMedia",
      
  };
  
  </script>
  
  <style>
  </style>