import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: false
  },
  getters: {
    getDrawer:(state)=> state.drawer
    
  },
  mutations: {
    setDrawer: (state,payload) => state.drawer= payload,
    toggleDrawer: (state) => state.drawer= !state.drawer
  },
  actions: {
  },
  modules: {
  }
})
