<template>
  <v-app>
    <appToolbar />
    <appDrawerVue />
    <v-main class="black">
      <router-view/>
    </v-main>
    <appFooterVue />
  </v-app>
</template>

<script>
import appDrawerVue from './components/core/appDrawer.vue';
import appFooterVue from './components/core/appFooter.vue';
import appToolbar from './components/core/appToolbar.vue';

export default {
  name: 'App',
  components: {appToolbar, appFooterVue, appDrawerVue},

  data: () => ({
    //
  }),
};
</script>
