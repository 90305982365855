<template>
  <v-container style="background-color: black">
    <!-- hero Section -->
    <v-row class="my-10 pa-5 ml-10">
      <v-col md="4" cols="12" class="text-center" sm="4">
        <img
          src="../assets//sonakshiImage.jpeg"
          style="border-radius: 22px; border: 3px solid; width: 100%"
          alt=""
        />
      </v-col>
      <v-col md="8" cols="12" class="" sm="8">
        <h1 class="google-font my-5 orange--text text--darken-1 mt-6">
          Hi,I'm Sonakshi,
        </h1>
        <h1 class="google-font my-5 white--text">I'm Versatile Developer</h1>
        <h4 class="google-font my-5 white--text">
          Frontend Developer (Vue.js, React.js, Vuetify, Quasar, Node.js)
        </h4>
        <p class="white--text">
          Over the past 3 years at agprop, I've honed my skills in Vue.js
          development, specializing in building responsive and interactive web
          solutions. My journey has been marked by a commitment to continuous
          learning, staying updated with the latest trends, and embracing
          challenges to deliver innovative solutions. I'm eager to leverage my
          expertise to drive impactful results in future projects.
        </p>
        <socialMedia class="my-5" />
        <v-btn depressed color="orange" class="mr-3" to="/hire-me">
          Hire Me
        </v-btn>
        <v-btn depressed outlined class="orange--text" to="/workspace">
          Explore my WorkSpace
        </v-btn>
      </v-col>
    </v-row>
    <!-- Hero section -->
    <!-- working section -->
    <v-row class="text-center my-10 pa-5" style="background-color: #212121">
      <v-col md="12" cols="12" sm="12">
        <h2
          class="google-font my-5 white--text"
          style="font-size: 35px; font-weight: 600"
        >
          I am working on It
        </h2>
        <p class="white--text">
          I subscribe to the idea of thinking outside of the conventional box.
          To me, the process of learning is ongoing and limitless, even as we
          age. My philosophy is to maintain a humble demeanor, make simple
          choices, and have big aspirations while staying grounded. This is my
          belief and I am steadfastly pursuing my goals with this mindset in my
          heart
        </p>
      </v-col>
      <v-col md="12" cols="12" style="width: 50%" sm="12">
        <v-chip
          v-for="(item, index) in technologies"
          :key="index"
          class="mx-2 white--text"
          style="background-color: black"
          small
        >
          {{ item }}
        </v-chip>
      </v-col>
    </v-row>
    <!-- working section -->
    <!-- Experience section -->
    <v-row class="my-10 pa-5">
      <v-col md="12" cols="12" sm="12">
        <h2
          class="google-font my-5 white--text text--darken-2"
          style="font-size: 35px; font-weight: 600"
        >
          Experiences
        </h2>
        <v-container fluid class="mx-0 px-0">
          <v-row class="my-2">
            <v-col md="12" cols="12" sm="12">
              <h3>Work Experience</h3>
            </v-col>
            <v-col
              md="4"
              v-for="(work, index) in workExperiences"
              :key="index"
              cols="12"
              sm="4"
            >
              <v-card class="mx-auto" outlined dark>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                      {{ work.duration.start }} - {{ work.duration.end }}
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      {{ work.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{
                      work.company
                    }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <!-- <v-list-item-avatar
                    tile
                    size="80"
                    color="grey"
                  ></v-list-item-avatar> -->
                </v-list-item>

                <v-card-actions>
                  <v-btn outlined rounded text :href="work.link" target="_blank"
                    >See More
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" cols="12" sm="12">
              <h3>Community Experience</h3>
            </v-col>
            <v-col
              md="4"
              v-for="(work, index) in communityExperiences"
              :key="index"
              cols="12"
            >
              <v-card class="mx-auto" outlined dark>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                      {{ work.duration.start }} - {{ work.duration.end }}
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      {{ work.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{
                      work.company
                    }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <!-- <v-list-item-avatar
                    tile
                    size="80"
                    color="grey"
                  ></v-list-item-avatar> -->
                </v-list-item>

                <v-card-actions>
                  <v-btn outlined rounded text :href="work.link"
                    >See More
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <!-- Experience section -->
  </v-container>
</template>

<script>
import socialMedia from "../components/common/socialMedia.vue";
export default {
  components: { socialMedia },
  name: "AppHero",
  data: () => ({
    technologies: ["HTML", "Vue.js", "CSS", "Firebase", "JavaScript", "React"],
    workExperiences: [
      {
        title: "Senior Frontend Developer",
        company: "agprop",
        duration: {
          start: "Dec 2022",
          end: "Present",
        },
        link: "https://www.linkedin.com/in/sonakshi15/",
      },
      {
        title: "Frontend Developer",
        company: "Magassians",
        duration: {
          start: "Jan 2022",
          end: "Nov 2022",
        },
        link: "https://www.linkedin.com/in/sonakshi15/",
      },
      {
        title: "Database Engineer",
        company: "Western Digital",
        duration: {
          start: "Jan 2020",
          end: "May 2022",
        },
        link: "https://www.linkedin.com/in/sonakshi15/",
      },
    ],
    communityExperiences: [
      {
        title: "Orgainser",
        company: "GDG",
        duration: {
          start: "April 2018",
          end: "July 2020",
        },
        link: "https://www.linkedin.com/in/sonakshi15/",
      },
      {
        title: "WTM Lead",
        company: "WTM Jalandhar",
        duration: {
          start: "April 2018",
          end: "July 2020",
        },
        link: "https://www.linkedin.com/in/sonakshi15/",
      },
    ],
  }),
};
</script>

<style></style>
